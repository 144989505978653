<template>
  <div class="main" data-title="消息通知" v-title>
    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <left-menu class="left-menu"></left-menu>
        <div class="right-main">
          <div class="head">
            <div class="head-title">消息通知</div>
            <div class="head-btns">
              <el-button @click="readAll" type="text">全部已读</el-button>
            </div>
          </div>

          <div class="body">
            <notice-list ref="notice" :searchKey="searchKey"></notice-list>
          </div>
          <div class="footer">
            <el-pagination :page-size="searchKey.count" @current-change="handleCurrentChange" :total="total" background layout="prev, pager, next"></el-pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import LeftMenu from '@/components/LeftMenu.vue'
import { provinceAndCityData } from 'element-china-area-data'
import NoticeList from '@/components/NoticeList.vue'
export default {
  components: {
    LeftMenu,
    NoticeList
  },
  data() {
    return {
      total: 0,
      searchKey: {
        page: 1,
        count: 15
      }
    }
  },
  methods:{
    //点击分页
    handleCurrentChange(val) {
      this.key.page = val
    },
    // setUnread(){
    //   this.$store.commit('set_unread')
    // },
    readAll(){
      this.$axios.post('/notice/read_all').then(res => {
        this.$refs.notice.getNoticeList()
        this.$store.commit('set_unread')
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}
.w100 {
  width: 100%;
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}
.msg-item {
  width: 100%;
  border-bottom: 1px rgba(0, 0, 0, 0.07) solid;
  padding: 10px 0;
  cursor: pointer;
  display: block;
  .item-top {
    display: flex;
    align-items: center;
    .title{
      flex: 1;
    }
    .title.is-read {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .content {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    padding: 10px 0;
  }
}
.footer {
  text-align: right;
  padding: 20px 0 0;
  margin-top: 20px;
}
</style>